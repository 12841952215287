let pullDownTestimonies = () => {
  if ($(window).width() <= 960) {
    $(".home-testimony-pull-down").css("margin-bottom", "auto");
  } else {
    const testimonyHeight = $(".home-testimony-pull-down").height();
    $(".home-testimony-pull-down").css(
      "margin-bottom",
      (testimonyHeight / 2 + 69) * -1
    );
  }
};

$(document).ready(function() {
  pullDownTestimonies();

  $(window).FontAwesomeConfig = {
    searchPseudoElements: true
  };
});

$(window).resize(function() {
  pullDownTestimonies();
});